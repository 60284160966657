// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-application-development-js": () => import("./../../../src/pages/application-development.js" /* webpackChunkName: "component---src-pages-application-development-js" */),
  "component---src-pages-application-re-engineering-js": () => import("./../../../src/pages/application-re-engineering.js" /* webpackChunkName: "component---src-pages-application-re-engineering-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-case-study-js": () => import("./../../../src/pages/case-study.js" /* webpackChunkName: "component---src-pages-case-study-js" */),
  "component---src-pages-e-aro-js": () => import("./../../../src/pages/e-aro.js" /* webpackChunkName: "component---src-pages-e-aro-js" */),
  "component---src-pages-e-commerce-js": () => import("./../../../src/pages/e-commerce.js" /* webpackChunkName: "component---src-pages-e-commerce-js" */),
  "component---src-pages-engineering-team-js": () => import("./../../../src/pages/engineering-team.js" /* webpackChunkName: "component---src-pages-engineering-team-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industrials-js": () => import("./../../../src/pages/industrials.js" /* webpackChunkName: "component---src-pages-industrials-js" */),
  "component---src-pages-marketing-js": () => import("./../../../src/pages/marketing.js" /* webpackChunkName: "component---src-pages-marketing-js" */),
  "component---src-pages-other-services-js": () => import("./../../../src/pages/other-services.js" /* webpackChunkName: "component---src-pages-other-services-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-quality-control-js": () => import("./../../../src/pages/quality-control.js" /* webpackChunkName: "component---src-pages-quality-control-js" */),
  "component---src-pages-real-estate-js": () => import("./../../../src/pages/real-estate.js" /* webpackChunkName: "component---src-pages-real-estate-js" */)
}

